<template>
       <router-view ></router-view>
</template>

<script>
import './assets/css/reset.css'


export default {
  name: 'App',
}
</script>
