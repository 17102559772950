import { createRouter,createWebHistory  } from "vue-router";
import Index from '../pages/index/index.vue'
export default createRouter({
      history: createWebHistory(),
      routes: [    
                {
                  path: "/",
                  redirect:'/index',
           
                },
                {
                  path: "/index",
                  name: "Index",
                  component:  Index,
                },
                {
                  path: "/product",
                  name: "Product",
                  component: () => import("../pages/product/index")
                },
                {
                  path: "/serve",
                  name: "Serve",
                  component: () => import("../pages/serve/index")
                },
                {
                  path: "/video",
                  name: "Video",
                  component: () => import("../pages/video/index")
                },
                {
                  path: "/help",
                  name: "Help",
                  component: () => import("../pages/help/index")
                },
                {
                  path: "/contact",
                  name: "Contact",
                  component: () => import("../pages/contact/index")
                },
                {
                  path: "/yjdetail",
                  name: "Yjdetail",
                  component: () => import("../pages/yjdetail/index")
                },
                {
                  path: "/allfunction",
                  name: "Allfunction",
                  component: () => import("../pages/allfunction/index")
                },
                {
                  path: "/load",
                  name: "Load",
                  component: () => import("../components/load.vue"),
                  meta: {
                    title: '加载中'
                  }
                },
                {
                  path: "/pay",
                  name: "Paybox",
                  query: { merchantsId: '1',storeId:'1',codeId:'1'},
                  component: () => import("../pages/paybox/index"),
                  meta: {
                    title: '加载中'
                  }
                },
                {
                path: "/success",
                  name: "Paysuccess",
                  query: { orderNo: '1'},
                  component: () => import("../pages/paybox/success")
                },
    ],
});
