<template>

    <header class="header" :class="headclass?'headclass':''">
                <router-link to="index"><img class="logo" src="../assets/img/logo.png" /></router-link>
                <ul class="headnav" :class="show?'smheadnav animated fadeInRight':''">
                     <router-link to="index"><li :class="url=='index'?'active':''">首页</li></router-link>
                     <router-link to="product"><li :class="url=='product'?'active':''">功能</li></router-link>
                     <router-link to="serve"><li :class="url=='serve'?'active':''">服务</li></router-link>
                     <router-link to="video"><li :class="url=='video'?'active':''">视频教程</li></router-link>
                     <router-link to="help"><li :class="url=='help'?'active':''">帮助中心</li></router-link>
                     <router-link to="contact"><li :class="url=='contact'?'active':''">联系我们</li></router-link>
                </ul>
                <img class="cateimg" @click="showcate()" src="../assets/img/cate.png" alt="" />
   </header>
</template>
<style>
.header{position: fixed;top: 0;left: 0;width: 100%;line-height: 64px;background: #FFFFFF;opacity: 0.64;z-index: 999;    border-bottom: 1px solid #f5f5f5;}
.header .logo{float: left;width: 198px;height: 44px;margin: 10px;}
.header .headnav{float: right; text-align: center;color: #666666;font-size: 16px;}
.header .headnav li{float: left; width: 96px; font-weight: bold;cursor: pointer;  border-bottom: 2px solid #fff;}
.header .headnav li.active{color: #347EDA;  border-bottom: 2px solid #347EDA;}
.header .headnav li:hover{color: #347EDA;    border-bottom: 2px solid #347EDA;}
.headclass{opacity:1;}

.cateimg{display: none;    height: 30px;    width: 30px;    margin: 16px;    position: absolute;    right: 0;}
.header .headnav a li{color: #666;}

@media only screen and (max-width:769px){
    .header {opacity: 1;}
    .header .headnav{display: none;    position: absolute;    right: 0;    background: #fff;    margin-top: 64px;    z-index: 999;}
    .header .headnav.smheadnav{display: block;}
    .header .headnav.smheadnav li{float: none;border-bottom: 0;}
    .header .headnav li.active{color: #347EDA;  border-bottom:0;}

    .cateimg{display: block;}

    .animated {
        animation-duration: 500ms !important;
        -webkit-animation-duration: 500ms !important;
        animation-fill-mode: both !important;
        -webkit-animation-fill-mode: both !important;
        animation-timing-function:ease-out;
    }


        /*从右到左进入*/
        .fadeInRight {
            animation-name: fadeInRight;
            -webkit-animation: fadeInRight;
        }
        /*从右到左进入*/
        @keyframes fadeInRight
        {
            0% {
               opacity: 0; 
               right: -50%;
            }
            100% {
              opacity: 1;
              right: 0;
            }
        }
        
        @-webkit-keyframes fadeInRight 
        {
          0% {
               opacity: 0; 
               right: -50%;
            }
            100% {
              opacity: 1;
              right: 0;
            }
        }
}
</style>
<script>

  export default {
      name: 'Header',
      props: {
           headclass: {
                type: Number,
                default: 1
           },
           url:{
                type: String,
                default: 'index'
           }
      },
     data() {
        return {   show:false }
    },

    methods: {
        showcate(){
            this.show=!this.show;
            window.scrollTo(0,0);
        }
    }
  }
</script>