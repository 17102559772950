<template>
   <div class="index" >
   <Header :headclass='headclass' url="index" />

   <swiper  :slides-per-view="1" :space-between="0">
           <swiper-slide  v-for="(item, index) in banner" :key="index"  :virtualIndex="index" ><img :src='item.img_url' alt=""></swiper-slide>
  </swiper>

   <div class="container">
            <ul class="adlup clearfix">
               <li v-for="(item,index) in adlist" :key="index">
                   <div class="addinfoleft">
                        <div class="adnumber color">0{{index+1}}</div>
                        <div class="adtitle color">{{item.title}}</div>
                        <div class="addesc line4">{{item.desc}}</div>
                        <img class="righticon" src="../../assets/img/right.png" alt="">
                   </div>
                   <div class="adimg"><img :src="item.img_url"  alt=""></div>
               </li>
            </ul>
   </div>

   <div class="maintwo clearfix">
        <div class="mainitem" v-for="(item,index) in Columntwo" :key="index">
                 <img class="mainicon" src="../../assets/img/bgwicon1.png" alt="">
                 <div class="maintitle">{{item.title}}</div>
                 <div style="padding: 20px 0;">_____</div>
                 <div class="maindesc">{{item.desc}}</div>
                 <a class="mainmore" :href="item.desc">MORE+</a>
        </div>
   </div>

   
    <div class="hander" v-for="(item,index) in Columnthree" :key="index">
            <div class="handertitle">{{item.title}}</div>
            <div class="handertitle2">{{item.titlezm}}</div>
            <div class="handertitle3">{{item.desc}}</div>
            <div class="smline">_______</div>
            <img :src="item.img_url" class="handimg" />
    </div>


    <div class="hander weixin" v-if="wexinxcx">
            <div class="weixinbox clearfix">
                        <div class="weixinleft clearfix">
                                <img class="wxphone" :src="wexinxcx.img_url" alt="">
                                <img class="wxphone" :src="wexinxcx.img_url2" alt="">
                        </div>
                        <div class="weixinleft">
                                <div class="weixintitle">{{wexinxcx.title}}</div>  
                                <div class="weixindesc">{{wexinxcx.desc}}</div> 
                                <div class="weixinewm clearfix">
                                            <div class="weixinitem">
                                                <img class="wxewm" :src="wexinxcx.wxewm" alt="">
                                                <div class="wxname">公众号二维码</div>
                                            </div>
                                            <div class="weixinitem">
                                                <img class="wxewm" :src="wexinxcx.xcxewm" alt="">
                                                <div class="wxname">小程序二维码</div>
                                        </div>
                                </div> 

                                <div class="weixinfont">扫码体验</div>

                        </div>

            </div>
    </div>

     <div class="hander tracking" v-if="Columnvideo">
             <div class="handertitle">{{Columnvideo.title}}</div>
             <div class="handertitle2">{{Columnvideo.titlezm}}</div>
             <div class="handertitle3">{{Columnvideo.desc}} </div>
             <div style="color: #6F6F6F;padding: 15px 0 26px;">_______</div>
              <div class="trackbox clearfix">
                        
                        <div class="video fl" @click="videoplay()">
                                <div id='videoControl'>
                                    <img class="videobgimg" src="../../assets/img/trackimg.png"/>
                                    <img class="videobtn" src="../../assets/img/playsm.png"/>
                                    <div class="videobgcss"></div>
                                </div>
                             
                              <video  id="myVideo" :src="Columnvideo.video" alt=""></video>
                        </div>
                        <div class="videorightbox fl">
                             <img class="trackimg" :src="Columnvideo.img_url" alt="">
                             <img class="trackimg downimg" :src="Columnvideo.img_url2" alt="">
                        </div>
             </div>
     </div>


     <div class="hander market">
            <div class="handertitle">多种营销玩法</div>
            <div class="handertitle2">Various marketing methods</div>
            <div class="handertitle3">拉新、转化、促活、复购、留存、推广，专业工具助力成交，洗衣经营面面俱到</div>
            <div>_______</div>

            <ul class="marklist clearfix">

                <li v-for="(item,index) in market" :key="index">
                    <img class="markicon" :src="item.img_url" alt="">
                    <div class="markname">{{item.title}}</div>
                    <div class="markdesc">{{item.desc}}</div>
                </li>
            </ul>


     </div>



     <div class="partner">
             <div class="pcontainer clearfix">
                    <div class="ptitle fl">
                            <div class="ptitle1">合作伙伴</div>
                            <div class="ptitle2">cooperative partner</div>
                    </div>

                    <img src="../../assets/img/partner1.png" class="partnerimg fl" />
                    <img src="../../assets/img/partner2.png" class="partnerimg fl" />
                    <img src="../../assets/img/partner3.png" class="partnerimg fl" />
                    <img src="../../assets/img/partner4.png" class="partnerimg fl" />


             </div>
         
     </div>



  <Footer />



  </div>
</template>

<script>
import '../../assets/css/index.css'
import Header from '../../components/header'
import Footer from '../../components/footer'

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import { getIndexData} from '../../request/api';



export default {
    name: 'Index',
    components: {
        Header,
        Footer,
        Swiper,
        SwiperSlide
    },
    data() {
      return {
           headclass:0,
           banner:[],
           adlist:[],
           Columntwo:[],
           Columnthree:[],
           market:[],
           config:[],
           wexinxcx:'',
      }
    },
    mounted(){
        window.addEventListener("scroll",this.windowScroll,true);
        this.getData();
    },
    methods: {
         getData(){
             let that = this;
             getIndexData().then(res=>{
                 that.banner = res.data.banner;
                 that.adlist = res.data.introduce;
                 that.market = res.data.market;
                 that.config = res.data.config;
                 that.wexinxcx = res.data.wexinxcx;
                 that.Columntwo =res.data.Columntwo;
                 that.Columnthree=res.data.Columnthree;
                 that.Columnvideo = res.data.Columnvideo;
             }) 
         },
         windowScroll(){
               let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
               if(scrollTop>100){
                      this.headclass = 1 
               }else{
                      this.headclass = 0
               }
        },

       videoplay() {
                 var x = document.getElementById("myVideo");
                if(x.paused){
                    document.getElementById("videoControl").style.display='none'
                    x.play();
                }else{
                    document.getElementById("videoControl").style.display='block'
                    x.pause();
                }
       }
    }

}
</script>

