<template>
    <footer class="footer">
         <div class="footcent clearfix">
                <div class="footerleft clearfix">
                            <img class="footlogo" src="../assets/img/logo2.png" alt="">
                            <div class="footlogo2">
                                <img class="footewm" src="../assets/img/ewm.jpg" alt="">
                                <div>微信公众号</div>
                            </div>
                            <div class="footlogo2">
                                <img class="footewm" src="../assets/img/ewm.jpg" alt="">
                                <div>微信客服号</div>
                            </div>
                </div>
               <div class="footerleft footerright">
                    <ul class="clearfix">
                         <li>
                             <div class="ftitle">产品</div>
                             <div class="color">____</div>
                             <div>功能</div>
                             <div>价格</div>
                             <div>大数据</div>
                             <div>人工智能</div>

                         </li>
                         <li>
                            <div class="ftitle">服务</div>
                            <div class="color">____</div>
                            <div>视频教程</div>
                            <div>帮助中心</div>
                            <div>案例</div>

                        </li>
                        <li>
                            <div class="ftitle">公司</div>
                            <div class="color">____</div>
                            <div>关于我们</div>
                            <div>加入我们</div>
                            <div>联系我们</div>
                        </li>
                    </ul>

               </div>

        </div>
        <div class="footline">
                <span>Copyright © 福楷 2021 fukai-tec.com</span>
                <span>{{config.name}} 版权所有</span>
                <span>{{config.beian}}</span>
                <span>全国热线：{{config.phone}}</span>

        </div>
    </footer>
</template>
<style>
.footer{position: relative;background: #272727;color: #dedddd;}
.footer .footcent{width:1080px;margin: auto;    padding: 40px 0 20px;}
.footer .footcent .footerleft{float: left;width: 50%;}
.footer .footcent .footerleft .footlogo2{padding: 20px 0;float: left;margin-right: 20px;text-align: center;}
.footer .footcent .footerleft .footlogo{width: 326px;height: 72px;display: block;}
.footer .footcent .footerleft .footewm{width: 96px;height: 96px;margin-bottom: 4px;}
.footer .footcent .footerright ul{margin-left: 90px;margin-top: 20px;}
.footer .footcent .footerright ul li{float: left;margin:0 40px;}
.footer .footcent .footerright ul li div{padding: 6px 0;font-size: 12px;cursor: pointer;}
.footer .footcent .footerright ul li div.ftitle{font-size: 16px;position: relative; }
.footer .footcent .footerright ul li div.color{    padding: 0; margin-bottom: 18px; }



.footline{border-top: 1px solid #666666;text-align: center;font-size: 12px;    padding: 10px 0;}
.footline span{padding: 0 10px;}


@media only screen and (max-width:769px){

    .footer .footcent{width: 100%;}
    .footer .footcent .footerleft .footlogo{    width: 32vw;    height: 7vw;    padding-left: 2vw;}
    .footer .footcent .footerleft .footlogo2{margin: 20px 20px 0;padding: 0;}
    .footer .footcent .footerright ul li{    margin: 0 25px;}
  @media only screen and (max-width: 415px){

      .footer .footcent{display: none;}
      .footline span{padding: 6px; display: block;}
  }
}
</style>
<script>
   import { getConfig } from '../request/api';
  export default {
      name: 'Footer',
      props: {
        msg: String
      },
     data() {
       return {
            config:{}
        }
    },
    mounted(){
          this.getdata();
    },
    methods: {
         getdata(){  
                let that = this;
                getConfig().then(res=>{
                        that.config = res.data;
                })
         }
    }
 
  };
</script>