import { get,post} from '../request/http';

//请求接口方法

export const getIndexData = p => get('index/index', p);
export const getConfig = p => get('index/config', p); //配置
export const getcate = p => get('index/helpcate', p); //问题分类
export const getquestion = p => get('index/question', p); //问题列表
export const getcontact = p => get('index/contact', p); //联系我们
export const getWxewm = p => get('index/getWxewm', p); //客服信息
export const gethelpVideo = p => get('index/helpVideo', p); //视频


export const getserve = p => get('index/serve', p); //服务中心


export const getgongneng = p => get('index/gongneng', p); //功能概况
export const gongnengFun = p => get('index/gongnengFun', p); //全部功能
export const gongnengYJ = p => get('index/gongnengYJ', p); //配套硬件


// export const getpay = p => post('http://192.168.0.18:8777/shouqianba/payCSB', p); //支付
export const getpayPre = p => get('https://api.fukai-tec.com/shouqianba/merchantsInfo', p); //支付前信息

export const getpay = p => post('https://api.fukai-tec.com/shouqianba/payCSB', p); //支付














